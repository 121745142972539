import React, { useState } from "react";
import "./footer.css";
import Toast from "./Toast";

const Footer = () => {
  const [toast, showToast] = useState(true);

  return (
    <>
      {toast && (
        <div className="toast">
          <Toast />
          <div style={{ padding: "12px" }}>
            <button onClick={() => showToast(false)}>Så gøy</button>
          </div>
        </div>
      )}
      <footer className="footer">
        <a
          href="https://woolit.no"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div>
            <p>Lagt med ♥ av woolit</p>
            <img
              src="https://assets.website-files.com/5d01fd05d66b6e3d11a13584/5d0203854e78f359a73d4fed_Vector.svg"
              alt=""
            />
          </div>
        </a>
        <button onClick={() => showToast(!toast)}>Strikk til meg!</button>
      </footer>
    </>
  );
};

export default Footer;
