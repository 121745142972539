import React from "react";

const Toast = () => (
  <div style={{ padding: "12px" }}>
    <h1>Strikk til meg!</h1>
    <p>
      Når du er fornøyd med votten din, trykk på <b>Ta bilde</b>-knappen.
    </p>
    <p>Last ned bildet.</p>
    <p>
      Gå til{" "}
      <a href="https://woolit.no/bestilling" target="_blank">
        Woolit.no
      </a>{" "}
      for å bestille.
    </p>
    <p>Der kan vi også hjelpe deg ferdigstille design og velge riktig garn!</p>
  </div>
);

export default Toast;
