import React, { useState } from "react";
import Grid from "./Grid";

const SizedGrid = () => {
  const [size, setSize] = useState("voksen");

  const sizes = ["voksen", "barn"];

  return (
    <div>
      <select onChange={(e) => setSize(e.target.value)}>
        {sizes.map((s) => (
          <option key={s}>{s}</option>
        ))}
      </select>
      <Grid size={size} />
    </div>
  );
};

export default SizedGrid;
