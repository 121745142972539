export const symbols = {
  increase: {
    label: "Øk 1 maske",
    svg: (
      <path
        d="M3.64645 4.85355C3.84171 5.04882 4.15829 5.04882 4.35355 4.85355L7.53553 1.67157C7.7308 1.47631 7.7308 1.15973 7.53553 0.964466C7.34027 0.769204 7.02369 0.769204 6.82843 0.964466L4 3.79289L1.17157 0.964466C0.976311 0.769204 0.659728 0.769204 0.464466 0.964466C0.269204 1.15973 0.269204 1.47631 0.464466 1.67157L3.64645 4.85355ZM3.5 4V4.5H4.5V4H3.5Z"
        fill="black"
      />
    ),
  },
  right: {
    label: "Fell mot høyre",
    svg: (
      <line
        x1="0.388634"
        y1="9.40426"
        x2="8.90333"
        y2="0.889566"
        stroke="black"
      />
    ),
  },
  left: {
    label: "Fell mot venstre",
    svg: (
      <line
        x1="1.09574"
        y1="0.888634"
        x2="9.61043"
        y2="9.40333"
        stroke="black"
      />
    ),
  },
  purl: {
    label: "Vrang maske",
    svg: <circle cx="4" cy="4" r="4" fill="black" />,
  },
  stitch: {
    label: "Rett maske",
    svg: <rect x="132" y="0" width="10" height="10" stroke="black" />,
  },
};

export const awareSymbols = (x, y, symbol) => {
  switch (symbol) {
    case "increase":
      return (
        <path
          d="M3.64645 4.85355C3.84171 5.04882 4.15829 5.04882 4.35355 4.85355L7.53553 1.67157C7.7308 1.47631 7.7308 1.15973 7.53553 0.964466C7.34027 0.769204 7.02369 0.769204 6.82843 0.964466L4 3.79289L1.17157 0.964466C0.976311 0.769204 0.659728 0.769204 0.464466 0.964466C0.269204 1.15973 0.269204 1.47631 0.464466 1.67157L3.64645 4.85355ZM3.5 4V4.5H4.5V4H3.5Z"
          fill="black"
        />
      );
    case "stitch":
      return <rect x={x} y={y} width="10" height="10" stroke="black" />;
    default:
      break;
  }
};
