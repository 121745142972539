import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addColor } from "../store/colorSlice";

import ColorType from "./ColorType";

const ColorList = () => {
  const dispatch = useDispatch();

  const colors = useSelector((state) => state.color.colors);

  return (
    <>
      {colors.map((color, index) => (
        <ColorType index={index} color={color} />
      ))}
      <button
        onClick={() => dispatch(addColor({ label: "Farge", hex: "#FF0000" }))}
      >
        Legg til farge
      </button>
    </>
  );
};

export default ColorList;
