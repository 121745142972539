import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { colorSlice } from "./colorSlice";
import { gridSlice } from "./gridSlice";

const rootReducer = combineReducers({
  color: colorSlice.reducer,
  grid: gridSlice.reducer,
});

const store = configureStore({ reducer: rootReducer });

export default store;
