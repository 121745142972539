import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import { updateColorValue, setActiveColorIndex } from "../store/colorSlice";
import { updateColorWithColorId } from "../store/gridSlice";

const ColorType = (props) => {
  const { index, color } = props;

  const [swatch, showSwatch] = useState(false);
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) => state.color.activeIndex);

  return (
    <>
      <div style={{ display: "flex" }}>
        <input
          placeholder="Fargenavn"
          value={color.label}
          onChange={(e) =>
            dispatch(
              updateColorValue({ index, update: { label: e.target.value } })
            )
          }
        />
        <div
          style={{
            backgroundColor: color.hex,
            height: "25px",
            width: "25px",
            border: `${activeIndex === index ? "2px" : "1px"} black solid`,
          }}
          onClick={() => dispatch(setActiveColorIndex(index))}
        />
        <button onClick={() => showSwatch(!swatch)}>
          {swatch ? "Lukk" : "Rediger"}
        </button>
      </div>
      {swatch && (
        <SketchPicker
          color={color.hex}
          onChangeComplete={(newCol) => {
            dispatch(updateColorValue({ index, update: { hex: newCol.hex } }));
            dispatch(updateColorWithColorId({ colorId: index, color: newCol }));
          }}
        />
      )}
    </>
  );
};

export default ColorType;
