import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGrid,
  toggleColorDrag,
  updateMirrorGrid,
} from "../store/gridSlice";
import Square from "./Square";
import { sizeData, squareSize } from "../utils/sizeData";
import { useScreenshot } from "use-react-screenshot";

const Grid = (props) => {
  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot();
  const [mirror, setMirror] = useState(false);
  const [drawGrid, setDrawGrid] = useState({});
  const [mirrorGrid, setMirrorGrid] = useState({});

  let gridId = 0;

  const dispatch = useDispatch();

  const getImage = () => takeScreenShot(ref.current);
  const baseColor = useSelector((state) => state.color.colors[0].hex);

  const drawSquare = (initial) => {
    const grid = {};
    const mirroredGrid = {};

    const size = sizeData[props.size];

    // Draw square
    for (let i = 0; i < size.row; i++) {
      const y = i * squareSize + squareSize * size.topHeight;
      for (let j = 0; j < size.col; j++) {
        gridId++;
        const x = j * squareSize;
        const mirrored_x = (size.col - (j + 1)) * squareSize;
        grid[gridId] = {
          x: x + initial,
          y: y,
          id: gridId,
          fill: baseColor,
          colorId: 0,
          symbolId: "stitch",
        };

        mirroredGrid[gridId] = {
          x: mirrored_x + initial,
          y: y,
          id: gridId,
          fill: baseColor,
          colorId: 0,
          symbolId: "stitch",
        };
      }
    }
    return [grid, mirroredGrid];
  };

  const drawTop = (initial) => {
    const size = sizeData[props.size];

    const grid = {};
    const mirroredGrid = {};

    let rowLength = size.topRow;
    for (let i = 0; i < size.topHeight; i++) {
      const y = i * squareSize;

      for (let j = 0; j < size.col; j++) {
        gridId++;
        if (j + 1 > rowLength) continue;
        const x = (size.col / 2 - i + j - 4) * squareSize;
        const mirrored_x =
          (size.col / 2 - i + (rowLength - 4 - (j + 1))) * squareSize;
        grid[gridId] = {
          x: x + initial,
          y: y,
          id: gridId,
          fill: baseColor,
          colorId: 0,
          symbolId: "stitch",
        };

        mirroredGrid[gridId] = {
          x: mirrored_x + initial,
          y: y,
          id: gridId,
          fill: baseColor,
          colorId: 0,
          symbolId: "stitch",
        };
      }
      rowLength += 2;
    }

    return [grid, mirroredGrid];
  };

  useEffect(() => {
    const size = sizeData[props.size];
    const tops = drawTop(size.offset * squareSize);
    const tops2 = drawTop((size.col + size.offset) * squareSize);

    const base1 = drawSquare(0);
    const base2 = drawSquare(size.col * squareSize);

    setDrawGrid({ ...tops[0], ...tops2[0], ...base1[0], ...base2[0] });
    setMirrorGrid({ ...tops[1], ...tops2[1], ...base1[1], ...base2[1] });

    dispatch(
      updateGrid({
        grid: { ...tops[0], ...tops2[0], ...base1[0], ...base2[0] },
      })
    );
    dispatch(
      updateMirrorGrid({ ...tops[1], ...tops2[1], ...base1[1], ...base2[1] })
    );
  }, [props.size]);

  return (
    <div style={{ textAlign: "center" }}>
      <button style={{ marginBottom: "10px" }} onClick={getImage}>
        Ta bilde!
      </button>
      {image && <img width="300px" src={image} alt={"ScreenShot"} />}
      <div>
        <label>Speil vott</label>
        <input type="checkbox" onChange={() => setMirror(!mirror)} />
      </div>
      <div ref={ref}>
        <h5>Venstre vott</h5>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <h6 style={{ margin: "24px" }}>Utside</h6>
          <h6 style={{ margin: "24px" }}>Innside</h6>
        </div>
        <div>
          <svg
            width={sizeData[props.size].width}
            height={sizeData[props.size].height}
            onMouseDown={() => dispatch(toggleColorDrag())}
            onMouseUp={() => dispatch(toggleColorDrag())}
          >
            {Object.keys(drawGrid).map((id) => (
              <Square key={id} {...drawGrid[id]} />
            ))}
            {[0, 1, 2, 3, 4].map((i) => (
              <line
                x1={(sizeData[props.size].width / 4) * i}
                y1="0"
                x2={(sizeData[props.size].width / 4) * i}
                y2={sizeData[props.size].height}
                stroke="red"
              />
            ))}
            {Array(sizeData[props.size].col * 2)
              .fill("b")
              .map((_, i) => (
                <text
                  style={{ fontSize: "10px", textAlign: "center" }}
                  x={12 * i}
                  y={sizeData[props.size].height}
                >
                  {(i % sizeData[props.size].col) + 1}
                </text>
              ))}
          </svg>
        </div>
        <div>
          <h5>Høyre vott</h5>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <h6 style={{ margin: "24px" }}>Utside</h6>
            <h6 style={{ margin: "24px" }}>Innside</h6>
          </div>
          <svg
            width={sizeData[props.size].width}
            height={sizeData[props.size].height}
            onMouseDown={() => dispatch(toggleColorDrag())}
            onMouseUp={() => dispatch(toggleColorDrag())}
          >
            {Object.keys(mirror ? mirrorGrid : drawGrid).map((id) => {
              const active = mirror ? mirrorGrid : drawGrid;
              return <Square key={id} {...active[id]} />;
            })}
            {[0, 1, 2, 3, 4].map((i) => (
              <line
                x1={(sizeData[props.size].width / 4) * i}
                y1="0"
                x2={(sizeData[props.size].width / 4) * i}
                y2={sizeData[props.size].height}
                stroke="red"
              />
            ))}
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Grid;
