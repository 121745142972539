import "./App.css";

import ColorList from "./Components/ColorList";

import { Provider } from "react-redux";
import store from "./store/store";
import SizedGrid from "./Components/SizedGrid";
import SymbolList from "./Components/SymbolList";
import Footer from "./Components/Footer";

function App() {
  return (
    <Provider store={store}>
      <h1 style={{ textAlign: "center", marginBottom: "24px" }}>
        Vottedesigner
      </h1>
      <div className="main">
        <div
          style={{
            display: "flex",
            width: "1080px",
            justifyContent: "space-between",
          }}
        >
          <SizedGrid />
          <div>
            <ColorList />
            <SymbolList />
          </div>
        </div>
      </div>
      <Footer />
    </Provider>
  );
}

/* 


        <ColorPicker />
        
      </div>


*/
export default App;
