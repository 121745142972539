export const squareSize = 12;
export const sizeData = {
  voksen: {
    row: 42,
    col: 30,
    topHeight: 11,
    width: 60 * 12,
    height: (42 + 13) * 12,
    topRow: 8,
    offset: 0
  },
  barn: {
    row: 30,
    col: 24,
    topHeight: 9,
    width: 48 * 12,
    height: (30 + 10) * 12,
    topRow: 6,
    offset: 1
  },
};
