import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  grid: {},
  mirrorGrid: {},
  mouseIsDown: false,
};

const gridSlice = createSlice({
  name: "grid",
  initialState,
  reducers: {
    updateGrid(state, action) {
      state.grid = action.payload.grid;
    },
    updateMirrorGrid(state, action) {
      state.mirrorGrid = action.payload.grid;
    },
    updateGridColor(state, action) {
      const { id, color, colorId } = action.payload;

      state.grid[id] = { ...state.grid[id], fill: color, colorId };
    },
    toggleColorDrag(state, action) {
      state.mouseIsDown = !state.mouseIsDown;
    },
    updateColorWithColorId(state, action) {
      const { colorId, color } = action.payload;
      const newGrid = {};
      Object.entries(current(state.grid)).map(([i, square]) => {
        if (square.colorId === colorId) {
          newGrid[i] = { ...square, fill: color.hex };
        } else {
          newGrid[i] = square;
        }
      });
      state.grid = newGrid;
    },
    updateSymbol(state, action) {
      const { index, symbol } = action.payload;
      state.grid[index].symbolId = symbol;
    },
  },
});

export const {
  updateGrid,
  updateGridColor,
  updateMirrorGrid,
  toggleColorDrag,
  updateColorWithColorId,
  updateSymbol,
} = gridSlice.actions;

export { gridSlice };
