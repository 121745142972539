import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSymbol } from "../store/colorSlice";
import { symbols } from "../utils/symbols";

const SymbolList = () => {
  const dispatch = useDispatch();
  const activeSymbol = useSelector((state) => state.color.activeSymbol);

  return (
    <>
      {Object.entries(symbols).map(([symbolKey, symbol]) => (
        <div
          onClick={() => dispatch(setActiveSymbol(symbolKey))}
          style={{
            width: "192px",
            height: "32px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: `${activeSymbol === symbolKey ? "1px #000000 solid" : ""}`,
          }}
        >
          <p>{symbol.label}</p>
          <svg width="24px" viewBox="0 0 10 10" fill="none">
            {symbol.svg}
          </svg>
        </div>
      ))}
    </>
  );
};

export default SymbolList;
