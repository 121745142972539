import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateGridColor, updateSymbol } from "../store/gridSlice";
import { awareSymbols } from "../utils/symbols";

const Square = (props) => {
  const activeColorIndex = useSelector((state) => state.color.activeIndex);
  const activeSymbol = useSelector((state) => state.color.activeSymbol);
  const colors = useSelector((state) => state.color.colors);

  const dispatch = useDispatch();
  const fill = useSelector((state) => state.grid.grid[props.id].fill);
  const symbolId = useSelector(state => state.grid.grid[props.id].symbolId)
  const colorDrag = useSelector((state) => state.grid.mouseIsDown);

  const handleMouseEvent = () => {};

  return (
    <g>
      <rect
        x={props.x}
        y={props.y}
        width="12"
        height="12"
        fill={fill}
        stroke="black"
      />
      <image
        onClick={() => {
          if (activeColorIndex === -1) {
            dispatch(updateSymbol({ index: props.id, symbol: activeSymbol }));
          } else {
            dispatch(
              updateGridColor({
                id: props.id,
                color: colors[activeColorIndex].hex,
                colorId: activeColorIndex,
              })
            );
          }
        }}
        onMouseOver={() => {
          if (colorDrag) {
            if (activeColorIndex === -1) {
              console.log("im used!", activeSymbol, props.symbolId);
              dispatch(updateSymbol({ index: props.id, symbol: activeSymbol }));
            } else {
              dispatch(
                updateGridColor({
                  id: props.id,
                  color: colors[activeColorIndex].hex,
                  colorId: activeColorIndex,
                })
              );
            }
          }
        }}
        href={`/symbols/${symbolId}.svg`}
        x={props.x}
        y={props.y}
        width="12"
        height="12"
      />
    </g>
  );
};

export default Square;
