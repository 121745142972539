import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  colors: [{ label: "Grunnfarge", hex: "#A06F50" }],
  color: "#A06F50",
  activeColor: "#A06F50",
  activeIndex: 0,
  activeSymbol: "no",
};

const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {
    updateColor(state, action) {
      state.color = action.payload.color;
    },
    addColor(state, action) {
      state.colors.push(action.payload);
    },
    updateColorValue(state, action) {
      const { index, update } = action.payload;
      state.colors[index] = { ...state.colors[index], ...update };
    },
    setActiveColor(state, action) {
      state.activeColor = state.colors[action.payload].hex;
    },
    setActiveColorIndex(state, action) {
      state.activeIndex = action.payload;
      state.activeSymbol = "no";
    },
    setActiveSymbol(state, action) {
      state.activeIndex = -1;
      state.activeSymbol = action.payload;
    },
  },
});

export const {
  updateColor,
  addColor,
  updateColorValue,
  setActiveColor,
  setActiveColorIndex,
  setActiveSymbol,
} = colorSlice.actions;

export { colorSlice };
